import { defineStore } from 'pinia'
import apiService from '@/services/apiService'

export const useWorkspaceStore = defineStore('workspaceStore', {
  state: () => {
    return {
      uuid: localStorage.getItem('workspace-uuid') || '',
      limitFileSize: 0,
      workflow: {
        'nodes': [],
        'edges': []
      },
      remainingDays: null
    }
  },
  actions: {
    async createWorkspace (data) {
      this.$reset()
      await apiService.createWorkspace(data)
      this.uuid = data.uuid
      localStorage.setItem('workspace-uuid', this.uuid)
    },
    loadWorkspace (uuid) {
      this.uuid = uuid
      localStorage.setItem('workspace-uuid', this.uuid)
    },
    closeWorkspace () {
      apiService.closeWorkspace().then(data => {
        this.uuid = ''
        // localStorage.removeItem('workspace-uuid')
        return data
      })
        .catch(() => {})
    },
    async getWorkflow () {
      return apiService.runRFunction({
        'func_name': 'r_extract_wgraph',
        'out_graph': true
      })
        .then(data => {
          this.updateWorkflow(data.Graph.data)
          return data
        })
        .catch(error => {
          throw error
        })
    },
    getRemainingDays () {
      return apiService.getRemainingDays()
        .then(data => {
          this.remainingDays = data.remainingDays
          return data
        })
        .catch(error => {
          throw error
        })
    },
    getLimitFileSize () {
      return apiService.getMetadata()
        .then(data => {
          this.limitFileSize = data.file_size_limit
          return data.file_size_limit
        })
        .catch(error => {
          throw error
        })
    },
    addDataset (dataset) {
      return apiService.addDataset(dataset)
        .then(data => {
          this.updateWorkflow(data.Graph.data)
          return data
        })
        .catch(error => {
          throw error
        })
    },
    addDemoDataset () {
      return apiService.addDemoDataset()
        .then(data => {
          this.updateWorkflow(data.Graph.data)
          return data
        })
        .catch(error => {
          throw error
        })
    },
    runRFunction (params) {
      return apiService.runRFunction(params)
        .then(data => {
          if (Object.keys(data).includes('Graph')) {
            this.updateWorkflow(data.Graph.data)
          }
          return data
        })
        .catch(error => { throw error })
    },
    updateWorkflow (workflow) {
      if (Array.isArray(workflow['nodes'])) {
        this.workflow['nodes'] = workflow['nodes']
      } else {
        this.workflow['nodes'] = []
      }
      if (Array.isArray(workflow['edges'])) {
        this.workflow['edges'] = workflow['edges']
      } else {
        this.workflow['edges'] = []
      }
    }

  },
  getters: {
    hasUuid: state => !!state.uuid,
    has2Dataset: state => state.workflow['nodes'].filter(obj => obj.type === 'dataset').length > 1,
    hasDataset: state => state.workflow['nodes'].filter(obj => obj.type === 'dataset').length > 0,
    datasets: state => state.workflow['nodes'].filter(obj => obj.type === 'dataset'),
    datasetsWithNumerical: state => state.workflow['nodes'].filter(obj => obj.type === 'dataset' && obj.meta.nbnum > 0),
    datasetsWithCategorical: state => state.workflow['nodes'].filter(obj => obj.type === 'dataset' && obj.meta.nbcat > 0),
    analyses: state => state.workflow['nodes'].filter(obj => obj.type === 'analysis'),
    analyses4table: state => state.workflow['nodes'].filter(obj => obj.type === 'analysis' || obj.type === 'savedPlots' || obj.type === 'combined'),
    combined: state => state.workflow['nodes'].filter(obj => obj.type === 'combined'),
    getSeed: state => parseInt('0x' + state.uuid.split('-')[1])
  }
})
