import axios from 'axios'
import api from '@/services/api'
import url from './urlEnvironment.js'
import pinia from '@/stores/initPinia.js'
import { useErrorStore } from '@/stores/error'
import { useWorkspaceStore } from '@/stores/workspacestore'

export default {
  runRFunction (payload) {
    // efface les erreurs pour tout sauf les functions suivantes
    if (!(['r_extract_wgraph', 'r_summary_description'].includes(payload.func_name))) {
      const errorStore = useErrorStore(pinia)
      if (typeof payload.keep_notifications !== 'undefined') {
        if (payload.keep_notifications === false) {
          errorStore.reset()
        }
        delete payload.keep_notifications
      } else {
        errorStore.reset()
      }
    }
    return api.post('run_r_function', payload)
      .then(response => response.data)
      .catch(error => {
        throw error.response
      })
  },
  getRemainingDays () {
    return api.post('get_remaining_days', {})
      .then(response => response.data)
      .catch(error => {
        throw error.response
      })
  },
  closeWorkspace () {
    return api.post('close_workspace', {})
      .then(response => response.data)
      .catch(error => {
        throw error.response
      })
  },
  createWorkspace (payload) {
    return api.post('create_workspace', payload)
      .then(response => response.data)
      .catch(error => {
        throw error.response
      })
  },
  UUIDExists (payload) {
    return api.post('uuid_exists', payload)
      .then(response => response.data)
      .catch(error => {
        throw error.response
      })
  },
  async getMetadata () {
    try {
      const response = await axios.post(
        url.API_URL + '/get_metadata')
      return response.data
    } catch (error) {
      throw error.response
    }
  },
  addDataset (payload) {
    let formData = new FormData()
    const useWorkspace = useWorkspaceStore(pinia)
    const uuid = useWorkspace.uuid
    formData.append('uuid', uuid)
    formData.append('file', payload['file'])
    const dblob = new Blob([JSON.stringify(payload['dataset'])], {
      type: 'application/json'
    })
    formData.append('dataset', dblob)
    const pblob = new Blob([JSON.stringify(payload['parameters'])], {
      type: 'application/json'
    })
    formData.append('parameters', pblob)
    return axios.post(
      url.ADD_DATASET_URL,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(response => response.data)
      .catch(error => {
        throw error.response
      })
  },
  addDemoDataset () {
    return api.post('load_demo_datasets', {})
      .then(response => response.data)
      .catch(error => {
        throw error.response
      })
  }

}
